<template>
  <v-dialog v-model="value" persistent max-width="800px">
    <v-form ref="form">
      <v-card>
        <v-card-title class="bg-gradient-primary-2">
          <span class="white--text"> View Logs </span>
        </v-card-title>
        <div class="body">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-left">Message</th>
                  <th class="text-left">Action By</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in transactions" :key="item.name">
                  <td>{{ item.date }}</td>
                  <td>{{ item.message }}</td>
                  <td v-if="item.actionBy">{{ getFullName(item.actionBy.user) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <ac-button title="Close" color="error" outlined @click="onClose" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ViewLogsModal',

  components: {
    AcButton: () => import('@/components/AcButton'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    transactions: {
      type: Array,
    },
  },

  computed: {
    ...mapGetters('app', ['selectedCompany']),
  },

  methods: {
    getFullName(user) {
      const { firstname, surname } = user
      const fullName = `${firstname} ${surname}`

      return this.removeUndefinedFromString(fullName)
    },
    onClose() {
      this.reset()
      this.$emit('input', false)
    },
    reset() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
