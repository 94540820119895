import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c(VCardTitle,{staticClass:"bg-gradient-primary-2"},[_c('span',{staticClass:"white--text"},[_vm._v(" View Logs ")])]),_c('div',{staticClass:"body"},[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Date")]),_c('th',{staticClass:"text-left"},[_vm._v("Message")]),_c('th',{staticClass:"text-left"},[_vm._v("Action By")])])]),_c('tbody',_vm._l((_vm.transactions),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.date))]),_c('td',[_vm._v(_vm._s(item.message))]),(item.actionBy)?_c('td',[_vm._v(_vm._s(_vm.getFullName(item.actionBy.user)))]):_vm._e()])}),0)]},proxy:true}])})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('ac-button',{attrs:{"title":"Close","color":"error","outlined":""},on:{"click":_vm.onClose}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }